<template>
  <div id="category-page">
    <AppContent
      class="category-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <b-row class="heading" no-gutters>
        <b-col cols="1">
          <b-icon
            class="back"
            icon="caret-left-fill"
            scale="2"
            variant="light"
            title="Back"
            @click="goBack"
          ></b-icon>
        </b-col>
        <b-col cols="10">
          <h3 class="text-center">{{ details.category_title }}</h3>
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
      
      <br>

      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-row>
            <b-col
              cols="12"
              lg="6"
              xl="3"
              v-for="index in perPage"
              :key="index">
              <b-skeleton-img
                animation="throb"
                width="250"
                height="250"
                aspect="1:1"
              ></b-skeleton-img>
              <div class="text-center mt-3 mb-3">
                <b-skeleton animation="throb" width="100%"></b-skeleton>
                <b-skeleton animation="throb" width="80%"></b-skeleton>
              </div>
            </b-col>
          </b-row>
        </template>

        <div class="videos" v-if="videos.length > 0">
          <b-row align-v="stretch">
            <b-col
              cols="12"
              lg="6"
              xl="3"
              v-for="(video, index) in videos"
              :key="index">
              <router-link
                class="link"
                :to="`/media/${video.media_id}/${video.wistia_id}`">
                <VideoCard :data="video" />
              </router-link>
            </b-col>
          </b-row>

          <div class="text-center mt-3">
            <b-button
              variant="primary"
              v-if="lastPage !== currPage"
              @click="loadMore">
              Load More
            </b-button>
          </div>
        </div>

        <AppNoContent class="no-content" v-else>
          <p class="mb-0">That category is still<br>not available.</p>
        </AppNoContent>
      </b-skeleton-wrapper>
    </AppContent>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading  : false,
        details  : {},
        videos   : [],
        currPage : 1,
        lastPage : 1,
        perPage  : 8,
      }
    },
    components : {
      'AppContent'   : () => import('@/components/layout/AppContent'),
      'AppNoContent' : () => import('@/components/layout/AppNoContent'),
      'VideoCard'    : () => import('./VideoCard'),
    },
    methods : {

      /**
       * Get Category details
       */
      getCategory() {
        this.$http.get('api/category', {
          params : {
            id : this.$route.params.id,
          },
        }).then(response => {
          this.details = response.data;
        });
      },

      /**
       * Get Category Videos
       */
      getCategoryVideos() {
        this.loading = true;
        this.$http.get('api/categoryvideo', {
          params : {
            categoryId : this.$route.params.id,
            page       : this.currPage,
            perPage    : this.perPage,
          },
        }).then(response => {
          this.currPage = response.data.result.currentPage;
          this.lastPage = response.data.result.lastPage;

          if (this.currPage > 1) {
            const arr = response.data.result.data;
            this.videos = this.videos.concat(arr);
          } else
            this.videos = response.data.result.data;
        }).catch(() => {
          this.videos = [];
        }).finally(() => {
          setInterval(() => {
            this.loading = false;
          }, 1500);
        });
      },

      /**
       * Go Back
       */
      goBack() {
        this.$router.go(-1);
      },

      /**
       * Load More Videos
       */
      loadMore() {
        this.currPage++;
        this.getCategoryVideos(this.currPage + 1);
      },
    },
    mounted() {
      this.getCategory();
      this.getCategoryVideos();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #category-page {
    background-image: $slide-2;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 100vh;
  }

  .category-content {
    padding-bottom: 3rem;
  }

  .back {
    margin-top: 2rem;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }

  .no-content {
    p {
      color: $tooltip-color;
      font-size: 1.25em !important;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .heading {
      margin-top: 1.75rem;
    }
    .back {
      margin-top: 0;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .heading {
      margin-top: 1.75rem;
    }
    .back {
      margin-top: 0.5rem;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .heading {
      margin-top: 2.5rem;
    }
    .back {
      margin-top: 1rem;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #category-page {
      padding-left: 5rem;
    }
    .category-content {
      margin: 0 auto;
      width: 50em;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    #category-page {
      padding-left: 5rem;
    }
    .category-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $special) {
    #category-page {
      padding-left: 5rem;
    }
    .category-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .category-content {
      margin: 0 auto;
      width: 80em;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .category-content {
      margin: 0 auto;
      width: 85em;
    }
    .back {
      margin-top: 1.75rem;
    }
  }
</style>